import React from "react"
import withI18next from "../components/withI18next";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/sections/Hero"
import { Linki18n } from "../components/Linki18n"
import browserRedirect from "../helpers/browserRedirect";

const NotFoundPage = ({ pageContext: { locale }, t }) => {
  
  browserRedirect(locale);

  return (
    <>
    <SEO title="404: Not found" />

    <Layout>
        <Hero />
        <section className="section">
          <div className="container my-5">
            <div className="card card-profile shadow mt--300 text-center">
              <div className="px-4 py-5">
                <h1>{t("notfound_title")}</h1>
                <p>{t("notfound_description")}</p>
                <Linki18n locale={locale} to="/" className="btn btn-primary mt-4">{t("notfound_btn_label")}</Linki18n>
              </div>
            </div>
          </div>
        </section>
    </Layout>
    </>
  );
};

export default withI18next({ ns: "common" })(NotFoundPage);
