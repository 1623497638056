/**
 * Redirect user based first on its localstorage then based on browser default.
 * only works if localstorage is supported by the webbrowser.
 */

import browserLang from "browser-lang";

// BrowserLang setup
const browserRedirect = (locale, defaultLang = "en") => {
  if (typeof window !== `undefined` && typeof(Storage) !== "undefined") {
    let storedLang = window.localStorage.getItem('lang');
    if (storedLang === null) {
      storedLang = browserLang({
        languages: ['fr', 'en'], 
        fallback: 'en',
      });
      window.localStorage.setItem('lang', storedLang);
    }

    // redirect to lang
    if (locale !== storedLang) {
      const pathname = window.location.pathname;
      if (locale === defaultLang) {
        window.location.replace(`/${storedLang}${pathname}`);
      } else {
        if (storedLang === defaultLang) {
          window.location.replace(`/${pathname.split("/").slice(2).join("/")}`)
        } else {
          window.location.replace(`/${storedLang}/${pathname.split("/").slice(2).join("/")}`)
        }
      }
    }
  }
};

export default browserRedirect;
